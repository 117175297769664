import configs from 'app/shared/data/configs';

/**
 * CI Engine
 * ----------------------------------------------
 */

// Subscriptions
export const httpCiEngineSubscriptions = (): string => `v${configs.version}/ci-engine/subscriptions`;
export const httpCiEngineSubscriptionUpgrade = (id: number): string => `v${configs.version}/ci-engine/subscriptions/${id}/upgrade`;

// Integrations
export const httpCiEngineSubscriptionIntegrations = (
  subscriptionId: number,
): string => `v${configs.version}/ci-engine/subscriptions/${subscriptionId}/integrations`;
export const httpCiEngineIntegrations = (): string => `v${configs.version}/ci-engine/integrations`;

// Images
export const httpCiEngineImages = (): string => `v${configs.version}/ci-engine/images`;

export const httpCiEngineSubscriptionImages = (
  subscriptionId: number,
): string => `v${configs.version}/ci-engine/subscriptions/${subscriptionId}/images`;
export const httpCiEngineImageActions = (
  subscriptionId: number,
  imageId: number,
): string => `v${configs.version}/ci-engine/subscriptions/${subscriptionId}/images/${imageId}/action`;

// Runners
export const httpCiEngineSubscriptionActiveRunners = (
  subscriptionId: number,
): string => `v${configs.version}/ci-engine/subscriptions/${subscriptionId}/runners`;
export const httpCiEngineIntegrationRunners = (
  subscriptonId: number,
  integrationId: number,
): string => `v${configs.version}/ci-engine/subscriptions/${subscriptonId}/integrations/${integrationId}/runners`;
export const httpCiEngineRunnerActions = (
  subscriptonId: number,
  integrationId: number,
  runnerId: number,
): string => `v${configs.version}/ci-engine/subscriptions/${subscriptonId}/integrations/${integrationId}/runners/${runnerId}/action`;

// SSE Topics
export const sseCiEngineSubscription = (): string =>
  `/v${configs.version}/ci-engine/subscription/{subscriptionId}`;

export const sseCiEngineIntegration = (): string =>
  `/v${configs.version}/ci-engine/subscription/{subscriptionId}/integration/{integrationId}`;

export const sseCiEngineRunner = (): string =>
  `/v${configs.version}/ci-engine/subscription/{subscriptionId}/runner/{runnerId}`;

export const sseCiEngineImage = (): string =>
  `/v${configs.version}/ci-engine/subscription/{subscriptionId}/image/{imageId}`;

