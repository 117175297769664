<div class="module-header" [ngClass]="{'collapsed': (showSidebar$ | async) === false, 'shadowed': windowScrolled}">
  <div class="header-title">
    <ng-content select="[header-title]"></ng-content>
  </div>

  <div class="header-menu">
    <ul>
      <li class="nav-item" [class.active]="isRouteActive(item.listOfLinks, item.blackListLinks)" *ngFor="let item of menulist">
        <a class="nav-link" [routerLink]="item.link">{{ item.name }}</a>
      </li>
    </ul>
    <a
        title="Back"
        [routerLink]="backLink | async"
        *ngIf="backLink | async"
        class="btn btn-primary btn-back back-link"
    >
      <i class="icon icon-back"></i>
    </a>

    <ng-template [ngTemplateOutlet]="customMenu"></ng-template>
  </div>

</div>
