import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OrderService } from 'app/admin/store/order';
import { QuotaService } from 'app/admin/store/quota';
import { OrganizationQuotaService } from 'app/admin/store/organization-quota';
import { DeviceElasticIpService } from 'app/baremetal/store/device-elastic-ip';
import { DeviceNetworkInterfaceService } from 'app/baremetal/store/device-network-interface';
import { DeviceSecurityGroupService } from 'app/baremetal/store/device-security-group';
import { ImageService } from 'app/compute/store/image';
import { InstanceService } from 'app/compute/store/instance';
import { InstanceElasticIpService } from 'app/compute/store/instance-elastic-ip';
import { InstanceLicenseService } from 'app/compute/store/instance-license';
import { InstanceNetworkInterfaceService } from 'app/compute/store/instance-network-interface';
import { InstanceSecurityGroupService } from 'app/compute/store/instance-security-group';
import { InstanceVolumeService } from 'app/compute/store/instance-volume';
import { KeyPairService } from 'app/compute/store/key-pair';
import { VolumeService } from 'app/compute/store/volume';
import { VolumeSnapshotService } from 'app/compute/store/volume-snapshot';
import { ClusterService } from 'app/kubernetes/store/cluster';
import { ClusterLoadBalancerService } from 'app/kubernetes/store/cluster-load-balancer';
import { ClusterNodeService } from 'app/kubernetes/store/cluster-node';
import { ClusterVolumeService } from 'app/kubernetes/store/cluster-volume';
import { CertificateService } from 'app/networking/store/certificate';
import { ElasticIpService } from 'app/networking/store/elastic-ip';
import { LoadBalancerService } from 'app/networking/store/load-balancer';
import { LoadBalancerPoolService } from 'app/networking/store/load-balancer-pool';
import { LoadBalancerPoolMemberService } from 'app/networking/store/load-balancer-pool-member';
import { PrivateNetworkService } from 'app/networking/store/private-network';
import { PrivateNetworkInstanceService } from 'app/networking/store/private-network-instance';
import { RouterService } from 'app/networking/store/router';
import { RouterInterfaceService } from 'app/networking/store/router-interface';
import { RouterRouteService } from 'app/networking/store/router-route';
import { SecurityGroupService } from 'app/networking/store/security-group';
import { SecurityGroupInstanceService } from 'app/networking/store/security-group-instance';
import { SecurityGroupRuleService } from 'app/networking/store/security-group-rule';
import { DeviceService } from 'app/baremetal/store/device';
import { ElasticIpService as MbmElasticIpService } from 'app/baremetal/store/elastic-ip';
import { PrivateNetworkService as MbmPrivateNetworkService } from 'app/baremetal/store/private-network';
import { PrivateNetworkDeviceService } from 'app/baremetal/store/private-network-device';
import { RouterService as MbmRouterService } from 'app/baremetal/store/router';
import { RouterInterfaceService as MbmRouterInterfaceService } from 'app/baremetal/store/router-interface';
import { SecurityGroupService as MbmSecurityGroupService } from 'app/baremetal/store/security-group';
import { SecurityGroupRuleService as MbmSecurityGroupRuleService } from 'app/baremetal/store/security-group-rule';
import { AccountService } from 'app/app-engine/store/account';
import { EnvironmentService } from 'app/app-engine/store/environment';
import { ApplicationTokensService } from 'app/organization/store/application-token';
import { PaymentHistoryService } from 'app/organization/store/payment-history';
import { EntityCacheService } from 'app/store/generic-store-infrastructure/entity-cache.service';
import { ObjectStorageService } from 'app/object-storage/services/object-storage/object-storage.service';
import { ObjectStorageBucketService } from 'app/object-storage/services/object-storage-bucket/object-storage-bucket.service';
import { ObjectStorageAccountsService } from 'app/object-storage/services/object-storage-accounts/object-storage-accounts.service';
import { TicketService } from 'app/tickets/store/tickets';
import { PlanService } from 'app/tickets/store/plans';
import { CategoryService } from 'app/tickets/store/categories';
import { SeverityService } from 'app/tickets/store/severities/severity.service';
import { VirtualPrivateNetworkService } from '../networking/store/virtual-private-network';
import { ReleaseNoteService, TutorialService, TutorialTypeService } from 'app/admin/store/dashboard';
import { SubscriptionService } from 'app/ci-engine/store/subscription';
import { IntegrationService } from 'app/ci-engine/store/integration';
import { SubscriptionFlavorService } from 'app/ci-engine/store/subscription-flavor/subscription-flavor.service';
import { ImageService as CiEngineImageService } from 'app/ci-engine/store/image';
import { SubscriptionImageService } from 'app/ci-engine/store/subscription-image';
import { SubscriptionRunnerService } from 'app/ci-engine/store/subscription-runner';
import { IntegrationRunnerService } from 'app/ci-engine/store/integration-runner';
import { SubscriptionIntegrationService } from 'app/ci-engine/store/subscription-integration';
import { NotificationService } from 'app/admin/store/notification';
import { OrganizationService } from 'app/admin/store/organization';
import { OrganizationAppEngineAccountService } from 'app/admin/store/organization-app-engine-account';
import { OrganizationClusterService } from 'app/admin/store/organization-cluster';
import { OrganizationModuleSubscriptionService } from 'app/admin/store/organization-module-subscription';
import { OrganizationProductInstanceService } from 'app/admin/store/organization-product-instance';
import { OrganizationSupportPlanService } from 'app/admin/store/organization-support-plan';
import { OrganizationUserService } from 'app/admin/store/organization-user';
import { UserService } from 'app/admin/store/user';
import { ImageOSService } from 'app/admin/store/image-os';
import { GlobalImageService } from 'app/admin/store/global-image';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  providers: [
    // Compute
    ImageService,
    InstanceService,
    InstanceElasticIpService,
    InstanceLicenseService,
    InstanceNetworkInterfaceService,
    InstanceSecurityGroupService,
    InstanceVolumeService,
    KeyPairService,
    VolumeService,
    VolumeSnapshotService,

    // Kubernetes
    ClusterService,
    ClusterLoadBalancerService,
    ClusterNodeService,
    ClusterVolumeService,

    // Networking
    CertificateService,
    ElasticIpService,
    LoadBalancerService,
    LoadBalancerPoolService,
    LoadBalancerPoolMemberService,
    PrivateNetworkService,
    PrivateNetworkInstanceService,
    RouterService,
    RouterInterfaceService,
    RouterRouteService,
    SecurityGroupService,
    SecurityGroupInstanceService,
    SecurityGroupRuleService,
    VirtualPrivateNetworkService,

    // Baremetal
    DeviceService,
    DeviceElasticIpService,
    DeviceNetworkInterfaceService,
    DeviceSecurityGroupService,
    MbmElasticIpService,
    MbmPrivateNetworkService,
    PrivateNetworkDeviceService,
    MbmRouterService,
    MbmRouterInterfaceService,
    MbmSecurityGroupService,
    MbmSecurityGroupRuleService,

    // CI Engine
    SubscriptionService,
    SubscriptionFlavorService,
    CiEngineImageService,
    SubscriptionImageService,
    SubscriptionRunnerService,
    SubscriptionIntegrationService,
    IntegrationService,
    IntegrationRunnerService,
    ImageOSService,
    GlobalImageService,

    // App Engine
    AccountService,
    EnvironmentService,

    // Object Storage
    ObjectStorageService,
    ObjectStorageBucketService,
    ObjectStorageAccountsService,

    //Support
    TicketService,
    PlanService,
    CategoryService,
    SeverityService,

    // Common
    ApplicationTokensService,
    PaymentHistoryService,

    // Admin
    ReleaseNoteService,
    TutorialService,
    TutorialTypeService,
    NotificationService,
    OrderService,
    OrganizationService,
    OrganizationAppEngineAccountService,
    OrganizationClusterService,
    OrganizationModuleSubscriptionService,
    OrganizationProductInstanceService,
    OrganizationQuotaService,
    OrganizationSupportPlanService,
    OrganizationUserService,
    QuotaService,
    UserService,
  ],
})
export class EntityStoreModule {
  constructor(
    entityCacheService: EntityCacheService,

    // Compute
    imageService: ImageService,
    instanceService: InstanceService,
    instanceElasticIpService: InstanceElasticIpService,
    instanceLicenseService: InstanceLicenseService,
    instanceNetworkInterfaceService: InstanceNetworkInterfaceService,
    instanceSecurityGroupService: InstanceSecurityGroupService,
    instanceVolumeService: InstanceVolumeService,
    keyPairService: KeyPairService,
    volumeService: VolumeService,
    volumeSnapshotService: VolumeSnapshotService,

    // Kubernetes
    clusterService: ClusterService,
    clusterLoadBalancerService: ClusterLoadBalancerService,
    clusterNodeService: ClusterNodeService,
    clusterVolumeService: ClusterVolumeService,

    // Networking
    certificateService: CertificateService,
    loadBalancerService: LoadBalancerService,
    loadBalancerPoolService: LoadBalancerPoolService,
    loadBalancerPoolMemberService: LoadBalancerPoolMemberService,
    privateNetworkService: PrivateNetworkService,
    privateNetworkInstanceService: PrivateNetworkInstanceService,
    routerService: RouterService,
    routerInterfaceService: RouterInterfaceService,
    securityGroupService: SecurityGroupService,
    securityGroupInstanceService: SecurityGroupInstanceService,
    securityGroupRuleService: SecurityGroupRuleService,
    vpnService: VirtualPrivateNetworkService,

    // MBM
    deviceService: DeviceService,
    deviceElasticIpService: DeviceElasticIpService,
    deviceNetworkInterfaceService: DeviceNetworkInterfaceService,
    deviceSecurityGroupService: DeviceSecurityGroupService,
    mbmElasticIpService: MbmElasticIpService,
    mbmPrivateNetworkService: MbmPrivateNetworkService,
    privateNetworkDeviceService: PrivateNetworkDeviceService,
    mbmRouterService: MbmRouterService,
    mbmRouterInterfaceService: MbmRouterInterfaceService,
    mbmSecurityGroupService: MbmSecurityGroupService,
    mbmSecurityGroupRuleService: MbmSecurityGroupRuleService,

    // CI Engine
    subscriptionService: SubscriptionService,
    subscriptionFlavorService: SubscriptionFlavorService,
    subscriptionImageService: SubscriptionImageService,
    subscriptionRunnerService: SubscriptionRunnerService,
    subscriptionIntegrationService: SubscriptionIntegrationService,
    integrationService: IntegrationService,
    integrationRunnerService: IntegrationRunnerService,

    // App Engine
    accountService: AccountService,
    environmentService: EnvironmentService,

    // Support
    ticketService: TicketService,
    planService: PlanService,
    categoryService: CategoryService,
    severityService: SeverityService,

    applicationTokensService: ApplicationTokensService,
    paymentHistoryService: PaymentHistoryService,

    // Object Storage
    objectStorageService: ObjectStorageService,
    objectStorageBucketService: ObjectStorageBucketService,
    objectStorageAccountsService: ObjectStorageAccountsService,

    orderService: OrderService,
    quotaService: QuotaService,
    organizationQuotaService: OrganizationQuotaService,
    releaseNoteService: ReleaseNoteService,
    tutorialService: TutorialService,
    tutorialTypeService: TutorialTypeService,
  ) {
    entityCacheService.registerServices([
      // Compute
      imageService,
      instanceService,
      instanceElasticIpService,
      instanceLicenseService,
      instanceNetworkInterfaceService,
      instanceSecurityGroupService,
      instanceVolumeService,
      keyPairService,
      volumeService,
      volumeSnapshotService,

      // Kubernetes
      clusterService,
      clusterLoadBalancerService,
      clusterNodeService,
      clusterVolumeService,

      // Networking
      certificateService,
      loadBalancerService,
      loadBalancerPoolService,
      loadBalancerPoolMemberService,
      privateNetworkService,
      privateNetworkInstanceService,
      routerService,
      routerInterfaceService,
      securityGroupService,
      securityGroupInstanceService,
      securityGroupRuleService,
      vpnService,

      // MBM
      deviceService,
      deviceElasticIpService,
      deviceNetworkInterfaceService,
      deviceSecurityGroupService,
      mbmElasticIpService,
      mbmPrivateNetworkService,
      privateNetworkDeviceService,
      mbmRouterService,
      mbmRouterInterfaceService,
      mbmSecurityGroupService,
      mbmSecurityGroupRuleService,

      // CI Engine
      subscriptionService,
      subscriptionFlavorService,
      subscriptionImageService,
      subscriptionRunnerService,
      subscriptionIntegrationService,
      integrationService,
      integrationRunnerService,

      // App Engine
      accountService,
      environmentService,

      // Support
      ticketService,
      planService,
      categoryService,
      severityService,

      applicationTokensService,
      paymentHistoryService,

      objectStorageService,
      objectStorageBucketService,
      objectStorageAccountsService,

      orderService,
      quotaService,
      organizationQuotaService,
      releaseNoteService,
      tutorialService,
      tutorialTypeService,
    ]);
  }
}

