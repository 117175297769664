/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Cluster,
  CreateCluster,
  KubeConfig,
  UpdateCluster,
  UpdateClusterFlavor,
  UpgradeCluster,
  Version,
} from 'app/entity/compute-kubernetes';
import { LocationService } from 'app/core/services/location';
import { KubernetesClusterConfiguration } from 'app/entity';
import { OrderMessagesService } from 'app/shared/services/messages/order-messages.service';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { ClusterDataService } from 'app/kubernetes/store/cluster/cluster.data';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { InvokeAction } from '../../../entity/common';
import { ToastrService } from 'ngx-toastr';
import { ToastPendingComponent } from '../../../shared/components';

@Injectable()
export class ClusterService extends GenericService<Cluster, CreateCluster, UpdateCluster> {
  constructor(
    store: Store<EntityCache>,
    orderMessagesService: OrderMessagesService,
    private clustersDataService: ClusterDataService,
    private locationService: LocationService,
    private toastrService: ToastrService,
  ) {
    super('Cluster', store, clustersDataService, orderMessagesService);
  }

  /** @deprecated use the change flavor function instead */
  upgrade(id: number, data: UpgradeCluster): Observable<Cluster> {
    // eslint-disable-next-line deprecation/deprecation
    return this.clustersDataService.upgrade(id, data).pipe(
      tap((cluster: Cluster) => this.updateOneInCache([], cluster)),
    );
  }

  changeFlavor(id: number, data: UpdateClusterFlavor): Observable<Cluster> {
    return this.clustersDataService.changeFlavor(id, data).pipe(
      tap(cluster => this.updateOneInCache([], cluster)),
      tap(cluster => {
        this.showMessageUntilUnlocked(
          cluster,
          `Cluster ${cluster.name} gets resized.`,
          `Succeeded to resize cluster ${cluster.name}.`,
        ).subscribe();
      }),
    );
  }

  invokeAction(id: number, action: InvokeAction, context?: number[]): Observable<Cluster> {
    return super.invokeAction(id, action, context).pipe(
      tap(cluster => this.updateOneInCache([], cluster)),
      tap(cluster => {
        this.showMessageUntilUnlocked(
          cluster,
          `Cluster ${cluster.name} gets reverted.`,
          `Succeeded to revert cluster ${cluster.name}.`,
        ).subscribe();
      }),
    );
  }

  revertAction(id: number): Observable<void> {
    return this.clustersDataService.revertAction(id);
  }

  getKubeConfig(id: number): Observable<KubeConfig> {
    return this.clustersDataService.getKubeConfig(id);
  }

  updateConfiguration(id: number, data: KubernetesClusterConfiguration): Observable<KubernetesClusterConfiguration> {
    return this.clustersDataService.updateConfiguration(id, data).pipe(
      tap(() => {
        this.getById(id).pipe(
          first(),
        ).subscribe(cluster => {
          this.showMessageUntilUnlocked(
            cluster,
            `Cluster ${cluster.name} gets updated.`,
            `Succeeded to update cluster ${cluster.name}.`,
          ).subscribe();
        });
      }),
    );
  }

  getConfiguration(id: number): Observable<KubernetesClusterConfiguration> {
    return this.clustersDataService.getConfiguration(id);
  }

  getConfigurationHistory(id: number): Observable<KubernetesClusterConfiguration> {
    return this.clustersDataService.getConfigurationHistory(id);
  }

  getKubernetesVersionList(): Observable<Version[]> {
    return this.clustersDataService.getKubernetesVersionList();
  }

  showMessageUntilUnlocked(cluster: Cluster, messagePending: string, messageSuccess: string): Observable<boolean> {
    const pending = this.toastrService.warning(messagePending, '', {
      toastComponent: ToastPendingComponent,
      enableHtml: false,
      disableTimeOut: true,
    });

    const completed$ = new BehaviorSubject<boolean>(false);

    const subscription = this.getById(cluster.id).pipe(
      tap(update => {
        if (update.locked) {
          return;
        }

        this.toastrService.success(messageSuccess, '', { enableHtml: true });
        this.toastrService.clear(pending.toastId);

        completed$.next(true);
        completed$.complete();

        subscription.unsubscribe();
      }),
    ).subscribe();

    return completed$.asObservable();
  }

  protected createDummyRecord(create: CreateCluster): Cluster {
    const location = this.locationService.moduleLocations(7).find(l => l.id === create.locationId);

    return {
      id: new Date().valueOf(),
      name: create.name,
      status: {
        id: 18,
        name: 'Creating',
        key: 'creating',
        actions: [],
      },
      location: {
        id: location.id,
        name: location.name,
        key: location.key,
      },
      product: null,
      network: null,
      securityGroup: null,
      nodeCount: {
        current: {
          'control-plane': 0,
          worker: 0,
        },
        expected: {
          'control-plane': 1,
          worker: create.worker.count || 0,
        },
      },
      expectedPreset: null,
      kubernetesVersion: null,
      dnsName: '-',
    };
  }
}
