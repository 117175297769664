import { AuthActions } from '../actions';

export interface State {
  isAuthed: boolean;
  token: string;
  pending: boolean;
  error: string | null;
}

export const initialState: State = {
  isAuthed: false,
  token: '',
  pending: false,
  error: null,
};

// eslint-disable-next-line default-param-last
export const reducer = (state: State = initialState, action: AuthActions.AuthActionsUnion): State => {
  switch (action.type) {
    case AuthActions.login.type: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case AuthActions.loginSuccess.type: {
      return {
        ...state,
        token: action.user.token,
        isAuthed: true,
        error: null,
        pending: false,
      };
    }

    case AuthActions.loginFailure.type: {
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    }

    case AuthActions.logout.type: {
      return initialState;
    }

    default:
      return state;
  }
};

export const getLoginPageError = (state: State): string => state.error;
export const getLoginPagePending = (state: State): boolean => state.pending;
export const getToken = (state: State): string => state.token;
export const getAuthed = (state: State): boolean => state.isAuthed;
