import { Injectable } from '@angular/core';
import { VNC } from 'app/entity';
import { Status } from 'app/entity/common';
import { CreateInstance, Instance, UpdateInstance } from 'app/entity/compute-instances';
import { Order } from 'app/entity/store/order';
import { ApiService } from 'app/shared/services/api';
import {
  httpComputedInstances,
  httpComputedInstanceActions,
  httpComputedInstanceVNC,
  httpComputedInstanceUpgrade,
  httpInstanceStatus,
} from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstanceDataService extends GenericDataService<Instance, CreateInstance, UpdateInstance> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpComputedInstances, apiService);
  }

  upgrade(id: number, product: { productId: number }): Observable<Order> {
    return this.apiService.post(httpComputedInstanceUpgrade(id), product);
  }

  postAction(id: number, action: string): Observable<Instance> {
    return this.apiService.post(httpComputedInstanceActions(id), { action });
  }

  getVncToken(id: number): Observable<VNC> {
    return this.apiService.get(httpComputedInstanceVNC(id));
  }

  getStatusActions(): Observable<Status[]> {
    return this.apiService.get(httpInstanceStatus());
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  deleteWithElasticIps(context: number[], id: number, params: { 'delete_elastic_ip': boolean }): Observable<void> {
    return this.apiService.delete(this.appendId(this.resolveUrl(context), id), { params });
  }
}
