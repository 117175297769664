import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpBackend, HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';

import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { AuthActions } from 'app/store/actions';
import * as fromAuth from 'app/store/reducers';

import configs from 'app/shared/data/configs';
import { environment } from 'environments/environment';

// @link https://github.com/angular/angular-cli/issues/7705
// @link https://github.com/angular/angular-cli/issues/10170
import { DialogsService } from '../dialogs/dialogs.service';
import { HttpOptions } from 'app/entity';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  api: string;
  error: string;
  authError: boolean;

  private httpWithoutInterceptor: HttpClient;
  private isImpersonate: boolean;

  constructor(
    httpBackend: HttpBackend,
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<fromAuth.State>,
    private dialogsService: DialogsService,
  ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);

    this.api = environment.apiHost;

    this.checkImpersonate();
  }

  get(path: string, options: Partial<HttpOptions> = {}, ownDomain: boolean = false): Observable<any> {
    const url = ownDomain ? '' : this.api;

    return this.http.get(`${url}${path}`, { ...options })
      .pipe(catchError(this.handleError(path)));
  }

  getWithoutInterceptor(path: string, options: Partial<HttpOptions> = {}, ownDomain: boolean = false): Observable<any> {
    const url = ownDomain ? '' : this.api;

    return this.httpWithoutInterceptor.get(`${url}${path}`, { ...options })
      .pipe(catchError(this.handleError(path)));
  }

  put(path: string, body: unknown = {}): Observable<any> {
    return this.http.put(
      `${this.api}${path}`,
      JSON.stringify(body),
    ).pipe(
      map(data => data === null ? true : data), // return true if we get succeeded empty content
      catchError(this.handleError(path)),
    );
  }

  post(path: string, body: unknown = {}): Observable<any> {
    return this.http.post(
      `${this.api}${path}`,
      JSON.stringify(body),
    ).pipe(
      map(data => data === null ? true : data), // return true if we get succeeded empty content
      catchError(this.handleError(path)),
    );
  }

  postFormData(path: string, body: unknown): Observable<any> {
    return this.http.post(
      `${this.api}${path}`,
      body,
    ).pipe(
      map(data => data === null ? true : data), // return true if we get succeeded empty content
      catchError(this.handleError(path)),
    );
  }

  patch(path: string, body: unknown = {}): Observable<any> {
    return this.http.patch(
      `${this.api}${path}`,
      JSON.stringify(body),
    ).pipe(
      map(data => data === null ? true : data), // return true if we get succeeded empty content
      catchError(this.handleError(path)),
    );
  }

  delete(path: string, options: HttpOptions = {}): Observable<any> {
    return this.http.delete(
      `${this.api}${path}`,
      { ...options },
    ).pipe(
      map(data => data === null ? true : data), // return true if we get succeeded empty content
      catchError(this.handleError(path)),
    );
  }

  uploadFile(path: string, formData: FormData): Observable<any> {
    return this.http.post(`${this.api}${path}`, formData, {
      observe: 'events',
      reportProgress: true,
    }).pipe(
      map(event => ApiService.getEventMessage(event)),
      catchError(this.handleError(path)),
    );
  }

  request(method: string, path: string, data?: unknown): Observable<any> {
    return this.http.request(
      method,
      `${this.api}${path}`,
      {
        body: data || null,
      },
    ).pipe(
      map((response: any) => response === null ? true : response), // return true if we get succeeded empty content
      catchError(this.handleError(path)),
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  // eslint-disable-next-line default-param-last
  private handleError<T>(operation: string = 'operation', _result?: T): (error: any) => Observable<never> {
    return (error: any): Observable<never> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      switch (error.status) {
        case 401: { // Authtoken end
          let errorResult = null;

          if (operation === `v${configs.version}/auth`) {
            this.showToastr(error.error);
            errorResult = throwError(() => ApiService.getErrorMessage(error.error));
          }

          // logout
          if (operation !== `v${configs.version}/auth`) {
            if (this.isImpersonate) {
              this.impersonateTokenExpiredModal();
            } else {
              this.authError = true;
              this.store.dispatch(AuthActions.logout(null));
              errorResult = throwError(() => ApiService.getErrorMessage(error.error));
            }
          }
          return errorResult;
        }

        case 503:
          this.showToastr(error.error);
          this.router.navigate([ 'maintenance' ]);
          // @todo use store
          this.error = ApiService.getErrorMessage(error.error);
          return throwError(() => error);

        case 424:
          this.toastr.info(ApiService.getErrorMessage(error.error));
          return throwError(() => error);

        default:
          this.showToastr(error.error);

          // Let the app keep running by returning an empty result.
          // return of(result as T);
          return throwError(() => error);
      }
    };
  }

  private showToastr(obj: any): void {
    this.toastr.error(ApiService.getErrorMessage(obj));
  }

  private impersonateTokenExpiredModal(): void {
    this.dialogsService.confirm({
      action: 'impersonate',
      title: 'Your impersonate session has expired',
      describe: 'Your impersonate session has expired. You can refresh your session if you need more time.',
      confirmLabel: 'Refresh Session',
    }).subscribe(data => {
      if (data) {
        window.location.reload();
      } else {
        window.close();
      }
      this.dialogsService.closeModal();
    });
  }

  private checkImpersonate(): void {
    this.store.select(fromAuth.getImpersonated)
      .pipe(
        tap(isImpersonate => this.isImpersonate = !!isImpersonate),
      )
      .subscribe();
  }

  private static getErrorMessage(obj: { error: { message: { en: string } } }): string {
    return obj ? obj.error.message.en : '';
  }

  private static getEventMessage(event: HttpEvent<any>): any {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        return { percent: Math.round(100 * event.loaded / event.total) };

      case HttpEventType.Response:
        return { token: event.body.token };

      default:
        return {};
    }
  }
}
