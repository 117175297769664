import configs from 'app/shared/data/configs';

/**
 * Admin
 * ----------------------------------------------
 */
//   - "/v4/admin/orders/{orderId}"

// Organization
export const httpAdminOrganizations = (): string => `v${configs.version}/admin/organizations`;
export const httpAdminOrganizationNotes = (orgId: number): string => `v${configs.version}/admin/organizations/${orgId}/notes`;
export const httpAdminOrganizationAssignUser = (orgId: number): string => `v${configs.version}/admin/organizations/${orgId}/assign-user`;
export const httpAdminOrganizationUsers = (orgId: number): string => `v${configs.version}/admin/organizations/${orgId}/users`;
export const httpAdminOrganizationUser = (
  orgId: number,
  userId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/users/${userId}`;
export const httpAdminOrganizationPaymentDetails = (
  orgId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/payment-details`;
export const httpAdminOrganizationCreditCards = (
  orgId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/payment-methods`;
export const httpAdminOrganizationBillingHistory = (
  orgId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/billing-history`;
export const httpAdminOrganizationProductInstances = (
  orgId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/product-instances`;
export const httpAdminOrganizationTransitions = (orgId: number): string => `v${configs.version}/admin/organizations/${orgId}/transitions`;
export const httpAdminOrganizationBonus = (orgId: number): string => `v${configs.version}/admin/organizations/${orgId}/bonus`;
export const httpAdminOrganizationChargeFee = (orgId: number): string => `v${configs.version}/admin/organizations/${orgId}/one-time-fee`;
export const httpAdminOrganizationModuleSubscriptions = (
  orgId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/module-subscriptions`;
export const httpAdminOrganizationModuleLocation = (
  orgId: number,
  moduleId: number,
  locationId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/modules/${moduleId}/locations/${locationId}`;
export const httpAdminOrganizationClusters = (
  orgId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/kubernetes/clusters`;
export const httpAdminOrganizationClusterPrivateKey = (
  orgId: number,
  clusterId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/kubernetes/clusters/${clusterId}/private-key`;
export const httpAdminOrganizationClusterFlavor = (
  orgId: number,
  clusterId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/kubernetes/clusters/${clusterId}/flavor`;
export const httpAdminOrganizationClusterCancelAction = (
  orgId: number,
  clusterId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/kubernetes/clusters/${clusterId}/cancel-action`;
export const httpAdminOrganizationClusterResetStatus = (
  orgId: number,
  clusterId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/kubernetes/clusters/${clusterId}/reset-status`;
export const httpAdminOrganizationAppEngineAccounts = (
  orgId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/app-engine/accounts`;
export const httpAdminOrganizationAppEngineAccountSSO = (
  orgId: number,
  accountId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/app-engine/accounts/${accountId}/manage`;
export const httpAdminOrganizationSupportPlan = (
  orgId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/support`;
export const httpAdminOrganizationQuotas = (
  orgId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/quotas`;
export const httpAdminOrganizationQuotaRules = (
  orgId: number,
  quotaId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/quotas/${quotaId}/rules`;
export const httpAdminOrganizationQuotaRule = (
  orgId: number,
  quotaId: number,
  ruleId: number,
): string => `v${configs.version}/admin/organizations/${orgId}/quotas/${quotaId}/rules/${ruleId}`;

// Dashboard
export const httpAdminDashboardTutorials = (): string => `v${configs.version}/admin/dashboard/tutorials`;
export const httpAdminDashboardTutorialTypes = (): string => `v${configs.version}/entities/dashboard-tutorial-types`;
export const httpAdminDashboardReleaseNotes = (): string => `v${configs.version}/admin/dashboard/release-notes`;

// Users
export const httpAdminUsers = (): string => `v${configs.version}/admin/users`;
export const httpAdminImpersonate = (): string => `v${configs.version}/admin/impersonate`;
export const httpAdminUser = (userId: number): string => `v${configs.version}/admin/users/${userId}`;
export const httpAdminUserNotes = (userId: number): string => `v${configs.version}/admin/users/${userId}/notes`;
export const httpAdminUserAvatar = (userId: number): string => `v${configs.version}/admin/users/${userId}/avatar`;
export const httpAdminUserActivation = (userId: number): string => `v${configs.version}/admin/users/${userId}/send-activation`;

// Quotas
export const httpAdminQuotas = (): string => `v${configs.version}/admin/quotas`;

// Orders
export const httpAdminOrders = (): string => `v${configs.version}/admin/orders`;

// Product Notifications
export const httpAdminProductNotifications = (): string => `v${configs.version}/admin/product-notifications`;
export const httpAdminProductNotification = (id: number): string => `v${configs.version}/admin/product-notifications/${id}`;
export const httpAdminProductNotificationNotify = (id: number): string => `v${configs.version}/admin/product-notifications/${id}/notify`;
