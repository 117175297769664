import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { Organization } from 'app/entity/infrastructure/organization.model';
import { OrganizationService } from 'app/organization/services/organization';
import { prebuildMenu } from 'app/shared/data/menu';
import { LocationService } from 'app/core/services/location';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { MessagesService } from 'app/shared/services/messages';
import { SseService } from 'app/shared/services/sse/sse.service';
import { OrganizationActions, UserActions, AuthActions } from 'app/store/actions';
import { getOrganization } from 'app/store/actions/organization.actions';
import { EntityCacheService } from 'app/store/generic-store-infrastructure/entity-cache.service';
import * as fromRoot from 'app/store/reducers';

import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { UserService } from 'app/admin/store/user';

@Injectable({
  providedIn: 'root',
})
export class ActiveOrganizationService {
  isImpersonate: boolean;
  isModuleGuardChecked: boolean;
  assignOrganizations: Organization[];
  private activeOrganization$ = new BehaviorSubject(null);

  constructor (
    private activeRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private store: Store<fromRoot.State>,
    private authService: AuthenticationService,
    private usersService: UserService,
    private orgService: OrganizationService,
    private router: Router,
    private messagesService: MessagesService,
    private sseService: SseService,
    private locationService: LocationService,
    private entityCacheService: EntityCacheService,
  ) {
  }

  get active(): Observable<Organization> {
    return this.activeOrganization$.asObservable();
  }

  async switchOrg(organization: Organization, doRedirect?: boolean): Promise<void> {
    if (this.activeOrganization$.value && organization.id === this.activeOrganization$.value.id) {
      return;
    }

    if (doRedirect) {
      try {
        await this.authService.fetchContextToken({ organizationId: organization.id });
      } catch {
        return;
      }
    }

    if (!this.isImpersonate) {
      this.localStorageService.setSessionObject('active-org', organization);
    }

    this.store.dispatch(OrganizationActions.setOrganization({ organization }));

    if (!this.isModuleGuardChecked) {
      this.locationService.setModules(organization.registeredModules);
      this.locationService.getAll().subscribe();
    }

    if (doRedirect) {
      this.redirectToDashboard()
        .then(() => this.resetAll());
    }

    this.activeOrganization$.next(organization);
  }

  checkActiveOrg(snapshot?: ActivatedRouteSnapshot): Promise<boolean> {
    const isImpersonateQuery = snapshot ?
      snapshot.queryParamMap.get('impersonate') :
      this.activeRoute.snapshot.queryParamMap.get('impersonate');

    if (!!isImpersonateQuery || this.localStorageService.getSessionObject('impersonated-user')) {
      return this.checkImpersonate(+isImpersonateQuery);
    }

    this.store.select(fromRoot.getUser)
      .pipe(
        first(),
        map(user => {
          this.assignOrganizations = user.assignedOrganizations;
          const activeOrg = this.localStorageService.getSessionObject('active-org');

          this.store.dispatch(getOrganization());

          this.switchOrg(activeOrg || user.defaultOrganization)
            .then(() => this.messagesService.connect());
        }),
      ).subscribe();

    return Promise.resolve(true);
  }

  rebuildLayout(modules: any[], snapshot?: any): void {
    const data = snapshot.data;

    this.locationService.setModules(modules);
    this.locationService.getAll().subscribe();
    this.isModuleGuardChecked = true;

    const isExist = !!modules.find(m => m.id === data.module);

    // Checking for available module to redirect
    if (!isExist && modules.length) {
      const module = prebuildMenu.find(item => item.id === modules[0].id);
      const link = module && module.items[0] ? module.items[0].link : '/support/tickets';
      if (link) {
        this.router.navigate([ link ], {
          queryParams: snapshot.queryParams,
        });
      }
    }
  }

  async redirectToDashboard(): Promise<void> {
    await this.router.navigate([ '/dashboard' ]);
  }

  private async checkImpersonate(isImpersonateQuery: number): Promise<boolean> {
    const impersonateUserId = isImpersonateQuery || this.localStorageService.getSessionObject('impersonated-user').id;
    return lastValueFrom(
        this.store.select(fromRoot.getImpersonated)
        .pipe(
          first(),
          map(async (impersonated) => {
            if (!impersonated && impersonateUserId) {
              return lastValueFrom(
                this.usersService.impersonate(impersonateUserId),
              ).then((user) => {
                this.store.dispatch(UserActions.impersonate({ user }));
                this.store.dispatch(AuthActions.loginSuccess({ user }));
                this.localStorageService.setSessionObject('impersonated-user', user);
                return user;
              })
              .then((user) => {
                this.assignOrganizations = user.assignedOrganizations as Organization[];
                const activeOrg = user.defaultOrganization as Organization ||
                  this.localStorageService.getSessionObject('impersonated-org');

                this.localStorageService.setSessionObject('impersonated-org', activeOrg);

                this.switchOrg(activeOrg)
                  .then(() => this.messagesService.connect());

                return Promise.resolve(true);
              });
            }

            if (impersonated) {
              return Promise.resolve(true);
            }

            return Promise.resolve(false);
          }),
        ),
      );
  }

  private resetAll(): void {
    this.entityCacheService.cleanUpCache();

    this.sseService.closeAll();

    this.store.dispatch(OrganizationActions.getOrganizationPayments());
    this.store.dispatch(OrganizationActions.getBalance());
  }
}
