export const environment = {
  production: false,
  apiHost: 'https://api.dev.flow.swiss/',
  hubHost: 'https://hub.dev.flow.swiss/.well-known/mercure',
  statusHost: 'https://status.flow.swiss/',
  termsAndConditions: 'https://flow.swiss/gtc',
  documentation: 'https://doc.flow.swiss/',
  basicHost: 'flow.swiss',
  stripeKey: 'pk_test_SjnK4LhcZPp6XnbZHSel1ZwD003vRKoAib',
  supportEmail: 'support@d3v-flowswiss.zendesk.com',
  brand: 'myflow',
  rechargeValues: {
    cardMin: 50,
    cardMax: 10000,
    cardSteps: [
      {
        amount: 50,
        cashbackPercent: 0,
      },
      {
        amount: 200,
        cashbackPercent: 0,
      },
      {
        amount: 500,
        cashbackPercent: 1,
      },
      {
        amount: 1000,
        cashbackPercent: 2,
      },
      {
        amount: 2500,
        cashbackPercent: 3,
      },
      {
        amount: 5000,
        cashbackPercent: 4,
      },
      {
        amount: 10000,
        cashbackPercent: 5,
      },
    ],
    invoiceMin: 500,
    invoiceMax: 50000,
    invoiceSteps: [
      {
        amount: 500,
        cashbackPercent: 1,
      },
      {
        amount: 1000,
        cashbackPercent: 2,
      },
      {
        amount: 2500,
        cashbackPercent: 3,
      },
      {
        amount: 5000,
        cashbackPercent: 4,
      },
      {
        amount: 10000,
        cashbackPercent: 5,
      },
      {
        amount: 20000,
        cashbackPercent: 7.5,
      },
      {
        amount: 50000,
        cashbackPercent: 10,
      },
    ],
  },
  cluster: {
    masterId: 46,
    masterGen2Id: 132,
    products: {
      max: 10,
      min: 3,
    },
  },
  status: {
    statusPage: 'https://status.flow.swiss',
    baseUrl: 'https://public-api.freshstatus.io/v1/',
    accountId: '38378',
    updateInterval: '120',
  },
  objectStorage: {
    baseStorage: 250, // in GB
    additionalGbCost: 0.04,
    additionalGbCostGen2: 0.044,
    endpoints: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ALP1: 'https://dev-s3-console-vip.alp1.flow.swiss/#endpoint=',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ALP2: 'https://dev-s3-console-vip.alp1.flow.swiss/#endpoint=',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ZRH1: 'https://dev-s3-console-vip.alp1.flow.swiss/#endpoint=',
    },
  },
  ciEngine: {
    additionalConcurrencyEssentialCost: 130,
    additionalConcurrencyEnterpriseCost: 350,
  },
};
