import { BaseEntity } from 'app/entity/store/base-entity';
import { MessageHandler, Status } from 'app/shared/services/messages/entity-connection';
import { GenericService } from 'app/store/generic-store-infrastructure/generic.service';
import { Message } from 'app/shared/services/sse/connection';

export class NgrxRerenderHandler<T extends BaseEntity> implements MessageHandler<T> {
  timeout: boolean;

  constructor(
    private service: GenericService<T, any, any>,
    private entityMapper?: (msg: Message<T>) => number,
  ) {
  }

  create(msg: Message<T>): void {
    this.rerender(msg);
  }

  async update(msg: Message<T>): Promise<void> {
    this.rerender(msg);
  }

  async status(_msg: Message<Status>): Promise<void> {
    this.rerender();
  }

  delete(msg: Message<T>): void {
    this.rerender(msg);
  }

  async rerender(msg?: Message<T>): Promise<void> {
    if (this.timeout || this.service.rerenderActiveId$ !== this.entityMapper(msg))
      return;
    this.timeout = true;
    this.service.getAll().subscribe();
    setTimeout(() => {
      this.timeout = false;
    }, 5000);
  }
}
