import { Injectable } from '@angular/core';

import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

import { Country } from 'app/entity/infrastructure/country.model';

import { ApiService } from 'app/shared/services/api';
import { LocalStorageService } from 'app/shared/services/local-storage.service';

import { httpCountries } from 'app/shared/utils/http.helper';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private countries$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor (
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
  ) { }

  get countriesList(): BehaviorSubject<any> {
    return this.countries$;
  }

  getCountries(): void {
    if (this.localStorageService.getObject('countries')) {
      const list = this.localStorageService.getObject('countries');
      this.countries$.next(list);
    } else {
      lastValueFrom(
        this.fetchCountries(),
      ).then((data: Country[]) => data.map(country => ({
        id: country.id,
        value: country.name,
        isoAlpha2: country.isoAlpha2,
        callingCode: country.callingCode,
        telValue: `${country.name} (${country.callingCode})`,
      })))
      .then(data => {
        this.countries$.next(data);
        this.localStorageService.setObject('countries', data);
      });
    }
  }

  private fetchCountries(): Observable<any> {
    return this.apiService.get(httpCountries(), {
      params: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'no_filter': 1,
      },
    });
  }
}
