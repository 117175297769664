import { Balance } from 'app/entity/billing/balance.model';
import { Organization, OrganizationContact } from 'app/entity/infrastructure/organization.model';
import { PaymentMethodModel } from 'app/entity/billing/payment-method.model';

import { OrganizationActions } from '../actions';

export interface State {
  active: Organization;
  payments: PaymentMethodModel[];
  balance: Balance;
}

export const initialState: State = {
  active: null,
  payments: null,
  balance: null,
};

export const reducer = (
  // eslint-disable-next-line default-param-last
  state: State = initialState,
  action: OrganizationActions.OrganizationActionsUnion,
): State => {
  switch (action.type) {
    case OrganizationActions.setOrganization.type: {
      return {
        ...state,
        active: action.organization,
      };
    }

    case OrganizationActions.getOrganizationPaymentsSuccess.type: {
      return {
        ...state,
        payments: action.payments,
      };
    }

    case OrganizationActions.getBalanceSuccess.type: {
      return {
        ...state,
        balance: action.balance,
      };
    }

    default: {
      return state;
    }
  }
};

export const getOrganization = (state: State): Organization => state.active;
export const getOrganizationPrimaryAccount = (state: State): OrganizationContact => state.active.contacts.primary;
export const getOrganizationPayments = (state: State): PaymentMethodModel[] => state.payments;
export const getBalance = (state: State): Balance => state.balance;
